import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Review, compare, and save outcomes to identify the perfect solution for your project.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Reviewing Saved Results</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Summary</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Metrics</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Deliverables</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Comparing Results</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Template Manager</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Creating Templates</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Deleting Templates</AnchorLink>
    </AnchorLinks>
    <p>{`The results tab allows you to review, filter and compare your saved Configurator results. `}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/126_results-overview.png",
      "alt": "126_results-overview.png"
    }}></img>
    <h2>{`Reviewing Saved Results`}</h2>
    <p>{`To review previously saved results:`}</p>
    <ol>
      <li parentName="ol">{`Click on the result listing in the `}<inlineCode parentName="li">{`Saved Results`}</inlineCode>{` table that you wish to review. `}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/126_results-overview.png",
      "alt": "126_results-overview.png"
    }}></img>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`The saved result `}<strong parentName="li">{`Summary`}</strong>{` will open. `}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/127_results-saved-result-overview.png",
      "alt": "127_results-saved-result-overview.png"
    }}></img>
    <h3>{`Summary`}</h3>
    <p>{`The Summary tab provides an overview of the results, the 3D model and access to the Product Inputs.`}</p>
    <p>{`To view the product inputs used when creating these results. Click the `}<inlineCode parentName="p">{`See Details`}</inlineCode>{` button next to the Product Input you wish to view. `}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/128_results-product-inputs.png",
      "alt": "128_results-product-inputs.png"
    }}></img>
    <p>{`The Saved Product Inputs will open for viewing.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/129_results-product-inputs-overview.png",
      "alt": "129_results-product-inputs-overview.png"
    }}></img>
    <h3>{`Metrics`}</h3>
    <p>{`The Metrics tab provides an overview of the Metrics and Tables for each product applied. `}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/130_results-metrics-overview.png",
      "alt": "130_results-metrics-overview.png"
    }}></img>
    <p>{`The Tables can be customised to suit your individual needs.`}</p>
    <p>{`You can choose to present the metrics as a:`}</p>
    <ul>
      <li parentName="ul">{`Bar Chart`}</li>
      <li parentName="ul">{`Donut Chart`}</li>
      <li parentName="ul">{`Pie Chart`}</li>
      <li parentName="ul">{`Line Chart`}</li>
      <li parentName="ul">{`Table`}</li>
    </ul>
    <p>{`To change the Table Type, use the table widgets in the top right of the panel.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/131_results-table-type.png",
      "alt": "131_results-table-type.png"
    }}></img>
    <p>{`Depending on the selected Table Type, you can adjust the metrics being used by selecting them from the drop-down lists.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/132_results-table-metrics.png",
      "alt": "132_results-table-metrics.png"
    }}></img>
    <h3>{`Deliverables`}</h3>
    <p>{`The Deliverables tab provides access to any drawings or models generated by the configurator.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/133_results-deliverables-overview.png",
      "alt": "133_results-deliverables-overview.png"
    }}></img>
    <p>{`To generate and download Model and Drawing deliverables:`}</p>
    <InlineNotification mdxType="InlineNotification">
A drawing template is required to generate and download the Model and Drawing deliverables.
    </InlineNotification>
    <ol>
      <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`Generate Deliverable`}</inlineCode>{` button. `}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/134_results-deliverables-generate-deliverable-button.png",
      "alt": "134_results-deliverables-generate-deliverable-button.png"
    }}></img>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`The `}<strong parentName="li">{`Settings for the new deliverable`}</strong>{` pop-up will open.`}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/135_deliverables-new-deliverable-pop-up.png",
      "alt": "135_deliverables-new-deliverable-pop-up.png"
    }}></img>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Complete the required information to generate the Deliverable:`}
        <ol parentName="li">
          <li parentName="ol">{`Revision`}</li>
          <li parentName="ol">{`Reason for Issue`}</li>
          <li parentName="ol">{`Drawing Scale`}</li>
          <li parentName="ol">{`Template`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Save`}</inlineCode>{` to start the Deliverable generation.`}</li>
      <li parentName="ol">{`After a brief loading time, the Deliverable will be available for download.`}</li>
    </ol>
    <h3>{`Comparing Results`}</h3>
    <p>{`To compare multiple sets of results on the same project:`}</p>
    <ol>
      <li parentName="ol">{`Tick the `}<inlineCode parentName="li">{`check box`}</inlineCode>{` on each result you wish to compare. `}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/136_results-picking-comparisons.png",
      "alt": "136_results-picking-comparisons.png"
    }}></img>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Compare Results`}</inlineCode>{` button. `}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/137_results-compare-button.png",
      "alt": "137_results-compare-button.png"
    }}></img>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`The Comparison window will open. `}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/138_results-compare-overview.png",
      "alt": "138_results-compare-overview.png"
    }}></img>
    <h2>{`Template Manager`}</h2>
    <p>{`The template manager allows you to create and manage templates for your activities.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/139_template-manager-overview.png",
      "alt": "139_template-manager-overview.png"
    }}></img>
    <h3>{`Creating Templates`}</h3>
    <p>{`To Create a Template:`}</p>
    <ol>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Create Template`}</inlineCode>{`. `}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/140_template-manager-create-template-button.png",
      "alt": "140_template-manager-create-template-button.png"
    }}></img>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`The Template creator will open. `}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/141_template-creation-general.png",
      "alt": "141_template-creation-general.png"
    }}></img>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Complete the details in the General section.`}</li>
      <li parentName="ol">{`Give the template a `}<strong parentName="li">{`Name`}</strong>{` and `}<strong parentName="li">{`Description`}</strong>{` so that you can sort and identify templates that have been customised for different purposes.`}</li>
      <li parentName="ol">{`Complete the `}<strong parentName="li">{`Building Element Category`}</strong>{` , `}<strong parentName="li">{`IFC Element Type Name`}</strong>{` , `}<strong parentName="li">{`Drawing Type`}</strong>{` , and `}<strong parentName="li">{`Grouping`}</strong>{` information boxes for your Template.`}</li>
      <li parentName="ol">{`Select a drawing type to expose different customisable drawing features.`}</li>
      <li parentName="ol">{`Select a Scope.`}</li>
      <li parentName="ol">{`Project - The template will be visible for this project only.`}</li>
      <li parentName="ol">{`Organisation - The template will be visible to every project within the organisation.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{` to go to the Sheet Properties section. `}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/142_template-creation-sheet-properties.png",
      "alt": "142_template-creation-sheet-properties.png"
    }}></img>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Choose a `}<inlineCode parentName="li">{`Drawing Size`}</inlineCode>{` and provide a `}<strong parentName="li">{`Drawing Number Design Function`}</strong>{`.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{` to go to the Views section. `}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/143_template-creation-views.png",
      "alt": "143_template-creation-views.png"
    }}></img>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Use the `}<inlineCode parentName="li">{`Tick boxes`}</inlineCode>{` to choose which Elevation views you want present on the sheet.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{` to go to the Dimensions section. `}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/144_template-creation-dimensions.png",
      "alt": "144_template-creation-dimensions.png"
    }}></img>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`(Optional) Choose what dimensions you want to add to the template and the Dimension Type.`}</li>
      <li parentName="ol">{`Press `}<inlineCode parentName="li">{`Next`}</inlineCode>{` to go to the Tables Section. `}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/145_template-creation-tables.png",
      "alt": "145_template-creation-tables.png"
    }}></img>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`(Optional) Use the tick-boxes to choose which columns to add to a table on the template.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Save`}</inlineCode>{` to add the Template to your project Templates.`}</li>
    </ol>
    <h3>{`Deleting Templates`}</h3>
    <p>{`To delete a template:`}</p>
    <ol>
      <li parentName="ol">{`Navigate to the Template Manager.`}</li>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`?`}</inlineCode>{` icon to the right of the template you wish to delete.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Delete`}</inlineCode>{`. `}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/146_template-deletion.png",
      "alt": "146_template-deletion.png"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      